import { Link, graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/MainLayout"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"

export default function Home({ data }) {
  let upComing = []
  let recent = []
  let activities = []
  let today = new Date().toISOString()

  data.allMarkdownRemark.nodes.forEach(el => {
    if (el.frontmatter.type === "SUCCESS") {
      activities.push(el)
    } else {
      if (new Date(el.frontmatter.date).toISOString() > today) {
        upComing.push(el)
      } else {
        recent.push(el)
      }
    }
  })
  return (
    <Layout>
      <div className="landing">
        <StaticImage
          className="img"
          src="../images/Hero.jpg"
          alt="Horizon"
          class="hero"
          quality={100}
        />
      </div>
      <div className="container my-5">
        <div className="heading">
          Mission and Vision
          <div className="underline"></div>
        </div>
        <div className="d-flex flex-md-row flex-wrap flex-column align-items-md-stretch align-items-center justify-content-center my-3">
          <div className="card shadow">
            <div className="card-body">
              <div className="cardTitle">What is IEEE ?</div>
              <div className="card-text">
                IEEE is the world's largest technical professional organization
                dedicated to advancing technology for the benefit of humanity.
              </div>
            </div>
          </div>
          <div className="card shadow">
            <div className="card-body">
              <div className="cardTitle">Mission</div>
              <div className="card-text">
                IEEE's core purpose is to foster technological innovation and
                excellence for the benefit of humanity.
              </div>
            </div>
          </div>
          <div className="card shadow">
            <div className="card-body">
              <div className="cardTitle">Vision</div>
              <div className="card-text">
                IEEE will be essential to the global technical community and to
                technical professionals everywhere, and be universally
                recognized for the contributions of technology and of technical
                professionals in improving global conditions.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="heading">
          Our IEEE Family
          <div className="underline"></div>
        </div>
        <div className="row row-cols-2 row-cols-lg-4 justify-content-around align-items-center p-4">
          <Link
            className="col p-4"
            to="chapter/pes-society"
          >
            <StaticImage
              className="society-img img-fluid"
              src="../images/PES_logo.png"
              alt=""
            />
          </Link>
          <Link
            className="col p-4"
            to="chapter/computer-society"
          >
            <StaticImage
              className="society-img img-fluid"
              src="../images/CS_logo.webp"
              alt=""
            />
          </Link>
          <Link
            className="col p-4"
            to="chapter/wie-society"
          >
            <StaticImage
              className="society-img img-fluid"
              src="../images/WIE_logo.png"
              alt=""
            />
          </Link>
          <Link
            className="col p-4"
            to="chapter/education-society"
          >
            <StaticImage
              className="society-img object-contain img-fluid"
              src="../images/EDU_Logo.png"
              alt=""
            />
          </Link>
        </div>
      </div>

      <div className="container my-5">
        <div className="heading">
          Our Strength
          <div className="underline"></div>
        </div>
        <div className="d-flex bg-dark justify-content-around my-4 rounded-xl">
          <div className={`text-center text-white py-4`}>
            {/* 90 -> 130 */}
            <div className="fs-2 fw-bold">110+</div>
            <div className="fw-bold fs-5">Members</div>
          </div>
          <div className={`text-center text-white py-4`}>
            {/* 10 -> 12 */}
            <div className="fs-2 fw-bold">13+</div>
            <div className="fw-bold fs-5">Years</div>
          </div>
          <div className={`text-center text-white py-4`}>
            <div className="fs-2 fw-bold">3</div>
            <div className="fw-bold fs-5">Chapters</div>
          </div>
          {/* Added AG. Might be note  */}
          <div className={`text-center text-white py-4`}>
            <div className="fs-2 fw-bold">1</div>
            <div className="fw-bold fs-5">AG</div>
          </div>
          {/* <div className={`text-center text-white py-4 hidden`}>
            <div className="fs-2 fw-bold">1</div>
            <div className="fw-bold fs-5">AG</div>
          </div> */}
        </div>
      </div>

      {upComing.length > 0 ? (
        <div className="container my-5">
          <div className="heading">
            Upcoming & Ongoing Events
            <div className="underline"></div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-center justify-content-center my-4">
            {upComing.map(el => (
              <Link
                key={el.frontmatter.title}
                className="col card my-2 shadow"
                to={`reg/${el.frontmatter.slug}`}
                style={{ maxWidth: "18rem" }}
              >
                <div>
                  <GatsbyImage
                    image={getImage(el.frontmatter.image)}
                    alt={el.frontmatter.title}
                    className="card-img"
                  />
                </div>
                <div className="spacer"></div>
                <div className="card-body center flex-column">
                  <div className="fw-bold text-center p-4 text-dark">
                    {el.frontmatter.title}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* Upcomming */}

      <div className="container my-5 rounded-xl bg-white p-4 shadow">
        <div className="heading">
          About Us
          <div className="underline"></div>
        </div>

        <div
          className="row mx-auto my-4 align-items-center"
          style={{ maxWidth: "1200px" }}
        >
          <div className="col-lg-6 my-2">
            <StaticImage
              className="rounded-xl"
              src="../images/About-1.png"
              alt="Horizon"
            />
          </div>
          <div className="col-lg-6">
            <p>
              <span className="fw-bold fs-3">W</span> ithin a decade of the
              establishment of the IEEE Student Branch in College of
              Engineering, Vadakara, Our Branch is said to be one of the active
              working SB in the Kerala State. Now IEEE SB CEV has a total of
              110+ Student Members and 3 Graduate Members.Our SB also has its
              three active societies, IEEE Computer Society with 50+ Active
              Members, IEEE Power Energy Society , IEEE Education Society and also the
              Women in Engineering Affinity Group (WiE AG) to motivate and
              support the young and talented students to achieve their greater
              future and career .
            </p>
            <Link className="btn btn-primary" to="/about-us">
              View more
            </Link>
          </div>
        </div>
      </div>

      {/* <div className="container my-5 rounded-xl bg-white p-4 shadow">
        <div className="heading">
          Highlights
          <div className="underline"></div>
        </div>

        <div
          className="row mx-auto my-4 align-items-center"
          style={{ maxWidth: "1200px" }}
        >
          <div className="col-lg-6 my-2">
            <StaticImage
              className="rounded-xl"
              src="../images/magnathon.png"
              alt="Horizon"
            />
          </div>
          <div className="col-lg-6">
            <p>
              <span className="fw-bold fs-3">Magnathon</span> 2022 is a themed
              hackathon event that focus on finding the best solution for
              problems centered on sustainability, energy use, climate change
              and anything technology focused on how we interact with the
              environment for a sustainable future. Hackathon allow the space
              for people to experience the reality of the challenge without
              committing to it long-term, and that may reveal skilled new talent
              from unexpected places.Magnathon '22 is a 24 hr offline event
              conducted as a part of CEV's tech fest Quasso Liberum 10.Lets get
              ready to bring out brainstorming ideas and win exciting
              cashprizes.
            </p>
            <Link className="btn btn-primary" to="/magnathon">
              View more
            </Link>
          </div>
        </div>
      </div> */}
      {/* About */}
      <div className="container my-5">
        <div className="heading">
          Recent Activities
          <div className="underline"></div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-center justify-content-center my-4">
          {activities.slice(0, 3).map(el => (
            <Link
              key={el.frontmatter.title}
              className="col card my-2 shadow"
              to={`activities/${el.frontmatter.slug}`}
              style={{ maxWidth: "18rem" }}
            >
              <div>
                <GatsbyImage
                  image={getImage(el.frontmatter.image)}
                  alt={el.frontmatter.title}
                  className="card-img"
                />
              </div>
              <div className="card-body d-flex felx-column center">
                <div className="fw-bold text-center text-dark">
                  {el.frontmatter.title}
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="d-flex justify-content-center my-4">
          <Link className="btn btn-primary" to="/activities">
            View More Events
          </Link>
        </div>
      </div>
      <div className="container my-5">
        <div className="heading">
          Event Registration
          <div className="underline"></div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-center justify-content-center my-4">
          {recent.slice(0, 3).map(el => (
            <Link
              key={el.frontmatter.title}
              className="col card my-2 shadow"
              to={`activities/${el.frontmatter.slug}`}
              style={{ maxWidth: "18rem" }}
            >
              <div>
                <GatsbyImage
                  image={getImage(el.frontmatter.image)}
                  alt={el.frontmatter.title}
                  className="card-img"
                />
              </div>
              <div className="card-body d-flex felx-column center">
                <div className="fw-bold text-center text-dark">
                  {el.frontmatter.title}
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="d-flex justify-content-center my-4">
          <Link className="btn btn-primary" to="/reg">
            View More
          </Link>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query Events {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 50
    ) {
      nodes {
        id
        frontmatter {
          type
          title
          slug
          date
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
